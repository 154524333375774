import React from "react";
import { FaFacebookSquare, FaYelp } from "react-icons/fa";
import "./Footer.css";
import { BiSolidNavigation } from "react-icons/bi";
import { BiSolidPhone } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="contact-info">
          <div className="icon-text">
            <BiSolidNavigation className="footer-icon" />
            <a
              href="https://maps.app.goo.gl/hG5PjKdUtM9NwBTFA"
              className="contact-text"
            >
              432 South Temple Street, Mesa, Arizona, 85204
            </a>
          </div>
          <div className="icon-text">
            <BiSolidPhone className="footer-icon" />
            <a href="tel:480-626-4153" className="contact-text">
              480-626-4153
            </a>
          </div>
          <div className="icon-text">
            <BiMailSend className="footer-icon" />
            <a href="mailto:info@meditationforyou.org" className="contact-text">
              info@meditationforyou.org
            </a>
          </div>
          <p>
            Can't find us? Well, we're a small house - no sign, 3rd on the right
            on Temple. Come help us grow!
          </p>
          <div className="social-media">
            <a
              href="https://www.facebook.com/meditationArizona/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FaFacebookSquare />
            </a>
            <a
              href="https://www.yelp.com/biz/arizona-international-buddhist-meditation-center-mesa"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FaYelp />
            </a>
          </div>
        </div>

        <div className="map-container">
          <div className="map-wrapper">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.6257168136062!2d-111.81363872431099!3d33.406927673406685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872ba87f8e8d0447%3A0x9b8768f8bf0a39fa!2sArizona%20International%20Buddhist%20Meditation%20Center%20INCORPORATION!5e0!3m2!1sen!2slk!4v1720935835642!5m2!1sen!2slk"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="fast"
              title="Google Map"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="bottom-p">
          © 2024 Arizona International Buddhist Meditation Center. Designed &
          developed by{" "}
          <a
            href="https://wacs.lk/"
            target="_blank"
            rel="noopener noreferrer"
            className="name-link"
          >
            WACS
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
